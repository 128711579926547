type TeamFields = {
  Name: string;
  People: string[];
};

export type RawTeam = {
  id: string;
  fields: TeamFields;
};

export type Team = {
  id: string;
  name: string;
  people: string[];
};

export enum Teams {
  CHATELAIN = 'Châtelain',
  CONSULTANT = 'Consultant',
  FREELANCE = 'Freelance',
  LEADER = 'Team Leader',
  PIC = 'PIC',
}
