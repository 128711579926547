import client, { DATA_ENDPOINT } from 'services/networking/client';
import { useDispatch } from 'react-redux';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import { TABLES } from 'module/serializer';
import { fetchPeopleSuccess } from './slice';
import { People } from './typings';

export const useGetPeople = () => {
  const dispatch = useDispatch();

  return useTypedAsyncFn<void>(async () => {
    const formula = '?filterByFormula=AND({Inactif}=FALSE())';
    const people: People[] = await client.get(DATA_ENDPOINT + TABLES.PEOPLE + formula);
    if (people) {
      dispatch(fetchPeopleSuccess(people));
    } else {
      throw new Error('error while trying to get People');
    }
    return people;
  }, [dispatch]);
};

export default { useGetPeople };
