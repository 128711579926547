export enum PROJECT_TYPES {
  CONSULTING = 'Conseil',
  HOLIDAYS = 'Congés',
  ABSENCE = 'Absence',
  INTERNAL = 'Interne',
  TRAINING = 'Formation',
}

export type Project = {
  id: string;
  name: string;
  type: PROJECT_TYPES;
  projectId: number;
  clientId: string | undefined;
  teamDay: boolean;
  briefLink: string;
  finished: boolean;
  standBy: boolean;
  seller: string;
  preparationDaysCount?: number;
  totalStaffingDays: number;
  alreadyStaffedDays: number;
};

type ProjectFields = {
  Name: string;
  Type: PROJECT_TYPES;
  'Projet ID': number;
  Clients?: string[];
  "Journée d'équipe ?": boolean;
  'Brief (Pipedrive)': string;
  Terminé: boolean;
  'Stand-By': boolean;
  'Vendeur (Auto)': string;
  '# de jours  Préparation (Camille)'?: number;
  'Somme du nombre de jours (Auto)': number;
  '# de journée staffés réservations exclues (Auto)': number;
};

export type RawProject = {
  id: string;
  fields: ProjectFields;
};

export type PostgresProject = {
  id: string;
  name: string;
  type: PROJECT_TYPES;
  projet_id: number;
  clients: string[];
  journée_déquipe: boolean;
  brief_pipedrive: string;
  terminé: boolean;
  stand_by: boolean;
  vendeur_auto: string;
  num_de_jours_préparation_camille: string | null;
  somme_du_nombre_de_jours_auto: string;
  num_de_journée_staffés_réservations_exclues_auto: string;
};
