import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/types';
import { Fee } from './typings';

export type FeeState = {
  ids: string[];
  entities: Record<string, Fee>;
};

export type FeeAction = PayloadAction<FeeState>;

const feeAdapter = createEntityAdapter<Fee>();

const feeSlice = createSlice({
  name: 'Fee',
  initialState: feeAdapter.getInitialState(),
  reducers: {
    /* eslint-disable @typescript-eslint/unbound-method */
    fetchFeesSuccess: (state, action) => {
      // Data can already exist in the store, making an addMany an incorrect choice
      // as addMany does not update existing entities.
      //
      // Furthermore, upsertMany does not handle removed properties in an entity correctly:
      // it keeps the old value for that property but updates the others.
      //
      // Finally, setAll would remove all values before adding the new ones, which
      // would work in this current use case, but might be a surprising behavior in
      // the future.
      const feeIds = action.payload.map((fee: Fee) => fee.id);
      feeAdapter.removeMany(state, { type: action.type, payload: feeIds });
      feeAdapter.addMany(state, action);
    },
    createFeesSuccess: feeAdapter.addMany,
    updateFeesSuccess: feeAdapter.updateMany,
    deleteFeesSuccess: feeAdapter.removeMany,
  },
});

export const { fetchFeesSuccess, createFeesSuccess, updateFeesSuccess, deleteFeesSuccess } =
  feeSlice.actions;

export const feeSelectors = feeAdapter.getSelectors<RootState>(state => state.fee);
export default feeSlice.reducer;
