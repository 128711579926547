import client, { DATA_ENDPOINT } from 'services/networking/client';
import { useDispatch, useSelector } from 'react-redux';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import { TABLES } from 'module/serializer';
import { decompressJSON } from 'services/networking/jsonCompressor';
import { fetchProjectSuccess, projectSelectors, updateProjectStaffedDays } from './slice';
import { Project } from './typings';

export const useGetProjects = () => {
  const dispatch = useDispatch();

  return useTypedAsyncFn<void>(async () => {
    const projects: Project[] = decompressJSON(await client.get(DATA_ENDPOINT + TABLES.PROJECT));
    if (projects) {
      dispatch(fetchProjectSuccess(projects));
    } else {
      throw new Error('error while trying to get Project');
    }
    return projects;
  }, [dispatch]);
};

export const useGetActiveProjects = () => {
  return useSelector(projectSelectors.selectAll).filter(
    project => !project.finished && !project.standBy,
  );
};

export const useUpdateProjectStaffedDays = () => {
  const dispatch = useDispatch();

  return {
    updateProjectStaffedDays: (projectId: string, daysToAddOrRemove: number) =>
      dispatch(updateProjectStaffedDays({ projectId, daysToAddOrRemove })),
  };
};

export default { useGetProjects };
