import { Fee, RawFee, serializeFeeForBack, serializeFeeForFront } from 'module/Fee';
import { RawTeam, serializeTeamForFront, Team } from './Teams';
import { People, RawPeople, serializePeopleForFront } from './People';
import {
  Planning,
  RawPlanning,
  serializePlanningForBack,
  serializePlanningForFront,
} from './Planning';
import { Project, RawProject, serializeProjectForFront } from './Project';
import { Client, RawClient, serializeClientForFront } from './Client';
import { Place, RawPlace, serializePlaceForFront } from './Place';
import { Equipment, RawEquipment, serializeEquipmentForFront } from './Equipment';

export enum TABLES {
  PLANNING = 'Planning',
  PEOPLE = 'Cubiks',
  TEAM = 'Rôles',
  PROJECT = 'Projets',
  CLIENT = 'Clients',
  FEE = 'Frais',
  PLACE = 'Lieu',
  EQUIPMENT = 'Matériel',
}

export enum REQUEST_TYPE {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const serializeDataForBack = (
  table: TABLES,
  data: People | Planning | Client | Team | Project | Fee | Place | Equipment,
  requestType: REQUEST_TYPE,
) => {
  switch (table) {
    case TABLES.PLANNING:
      return serializePlanningForBack(data as Planning, requestType);
    case TABLES.FEE:
      return serializeFeeForBack(data as Fee, requestType);
    default:
      return data;
  }
};

export const serializeDataForFront = (
  table: TABLES,
  data:
    | RawPeople
    | RawPlanning
    | RawClient
    | RawTeam
    | RawProject
    | RawFee
    | RawPlace
    | RawEquipment,
) => {
  switch (table) {
    case TABLES.PLANNING:
      return serializePlanningForFront(data as RawPlanning);
    case TABLES.PEOPLE:
      return serializePeopleForFront(data as RawPeople);
    case TABLES.TEAM:
      return serializeTeamForFront(data as RawTeam);
    case TABLES.PROJECT:
      return serializeProjectForFront(data as RawProject);
    case TABLES.CLIENT:
      return serializeClientForFront(data as RawClient);
    case TABLES.FEE:
      return serializeFeeForFront(data as RawFee);
    case TABLES.PLACE:
      return serializePlaceForFront(data as RawPlace);
    case TABLES.EQUIPMENT:
      return serializeEquipmentForFront(data as RawEquipment);
    default:
      return data;
  }
};
