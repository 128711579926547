import { REQUEST_TYPE } from 'module/serializer';
import moment from 'moment';
import { DAY_TIME, Planning, PostgresPlanning, RawPlanning } from './typings';

export const serializePlanningForBack = (
  data: Planning,
  requestType: REQUEST_TYPE,
): { id?: RawPlanning['id']; fields: RawPlanning['fields'] } => {
  const fields = {
    People: [data.peopleId],
    'Date où il est staffé': data.staffingDate,
    Projet: data.projectId ? [data.projectId] : undefined,
    'AM / PM': data.isAM ? DAY_TIME.AM : DAY_TIME.PM,
    'Facturé ?': data.invoiced,
    'Contraintes personnelles': data.personalConstraints,
    'Compagnonnage ? (Conseil)': data.companionship,
    'Compagnonnage - Freelance Facturé': data.invoicedCompanionship,
    "Type d'intervention (Formation)": data.interventionType,
    Détails: data.details,
    'Numero de la journée (Formation)': data.dayNumber,
    'Lieu (Formation)': data.place,
    'Materiel spécifique (Formation)': data.specificMaterial,
    'Work at home': data.workAtHome,
    Mergé: data.isMerged,
    'Confirmé consultant': data.consultantConfirmation,
    'Validation timekeeping': data.consultantValidation,
    Frais: data.hasFees !== '' ? data.hasFees : undefined,
    'Liste des Frais': data.feeList,
    Réservation: data.isReservation,
    Conflit: data.isConflict,
    'Projet en conflit': data.conflictingProjectId ? [data.conflictingProjectId] : undefined,
    'Déj payé par Client/Cubik': data.mealVoucher !== '' ? data.mealVoucher : undefined,
    'Facturé freelance': data.invoicedFreelance,
  };

  switch (requestType) {
    case REQUEST_TYPE.POST:
      return { fields };
    case REQUEST_TYPE.PUT:
      return { id: data.id, fields };
    default:
      return { fields };
  }
};

export const serializePlanningForFront = (data: RawPlanning): Planning => ({
  id: data.id,
  peopleId: data.fields.People && data.fields.People[0],
  projectId: data.fields.Projet && data.fields.Projet[0],
  staffingDate: data.fields['Date où il est staffé'],
  isAM: !!(data.fields['AM / PM'] === 'AM'),
  name: data.fields.Name,
  interventionType: data.fields["Type d'intervention (Formation)"],
  companionship: !!data.fields['Compagnonnage ? (Conseil)'],
  invoicedCompanionship: !!data.fields['Compagnonnage - Freelance Facturé'],
  projectType: data.fields['Type de projet'] && data.fields['Type de projet'][0],
  projectName: data.fields['Nom du projet (app)'] && data.fields['Nom du projet (app)'],
  personalConstraints: data.fields['Contraintes personnelles'] ?? '',
  invoiced: !!data.fields['Facturé ?'],
  invoicedFreelance: !!data.fields['Facturé freelance'],
  details: data.fields['Détails'] ?? '',
  teamDay: !!(data.fields["Journée d'équipe ?"] && data.fields["Journée d'équipe ?"][0]),
  dayNumber: data.fields['Numero de la journée (Formation)'],
  place: data.fields['Lieu (Formation)'] ?? '',
  specificMaterial: data.fields['Materiel spécifique (Formation)'] ?? '',
  briefLink: data.fields['Brief (Pipedrive)'] && data.fields['Brief (Pipedrive)'][0],
  workAtHome: !!data.fields['Work at home'],
  isMerged: !!data.fields.Mergé,
  consultantConfirmation: !!data.fields['Confirmé consultant'],
  consultantValidation: !!data.fields['Validation timekeeping'],
  hasFees: data.fields.Frais,
  feeList: data.fields['Liste des Frais'] ?? [],
  isReservation: !!data.fields['Réservation'],
  isConflict: !!data.fields.Conflit,
  conflictingProjectId: data.fields['Projet en conflit'] && data.fields['Projet en conflit'][0],
  seller: data.fields.Vendeur && data.fields.Vendeur[0],
  mealVoucher: data.fields['Déj payé par Client/Cubik']
    ? data.fields['Déj payé par Client/Cubik']
    : '',
  billed: !!data.fields['Etat de Facturation'],
});

export const serializePostgresPlanningForFront = (data: PostgresPlanning): Planning => ({
  id: data.id,
  peopleId: data.people && data.people[0],
  projectId: data.projet && data.projet[0],
  staffingDate:
    data.date_où_il_est_staffé && moment(data.date_où_il_est_staffé).format('YYYY-MM-DD'),
  isAM: !!(data.am_pm === 'AM'),
  name: data.name,
  interventionType: data.type_dintervention_formation,
  companionship: !!data.compagnonnage_conseil,
  invoicedCompanionship: !!data.compagnonnage_freelance_facturé,
  projectType: data.type_de_projet && data.type_de_projet[0],
  projectName: data.nom_du_projet_app && data.nom_du_projet_app,
  personalConstraints: data.contraintes_personnelles ?? '',
  invoiced: !!data.facturé,
  invoicedFreelance: !!data.facturé_freelance,
  details: data.détails ?? '',
  teamDay: !!(data.journée_déquipe && data.journée_déquipe[0]),
  dayNumber: parseInt(data.numero_de_la_journée_formation, 10),
  place: data.lieu_formation ?? '',
  specificMaterial: data.materiel_spécifique_formation ?? '',
  briefLink: data.brief_pipedrive && data.brief_pipedrive[0],
  workAtHome: !!data.work_at_home,
  isMerged: !!data.mergé,
  consultantConfirmation: !!data.confirmé_consultant,
  consultantValidation: !!data.validation_timekeeping,
  hasFees: data.frais,
  feeList: data.liste_des_frais ?? [],
  isReservation: !!data.réservation,
  isConflict: !!data.conflit,
  conflictingProjectId: data.projet_en_conflit && data.projet_en_conflit[0],
  seller: data.vendeur && data.vendeur[0],
  mealVoucher: data.déj_payé_par_client_cubik ? data.déj_payé_par_client_cubik : '',
  billed: !!data.etat_de_facturation,
});
