import client, { DATA_ENDPOINT } from 'services/networking/client';
import { useDispatch } from 'react-redux';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import { TABLES } from 'module/serializer';
import { Update } from '@reduxjs/toolkit';

import { createFeesSuccess, deleteFeesSuccess, fetchFeesSuccess, updateFeesSuccess } from './slice';
import { Fee } from './typings';

export const useGetFeesByIds = () => {
  const dispatch = useDispatch();

  return useTypedAsyncFn<string[]>(
    async (ids: string[]) => {
      const idFilterPredicates = ids.map(id => `RECORD_ID()='${id}'`);
      const formula = `?filterByFormula=OR(${idFilterPredicates.join(',')})`;
      const fees: Fee[] = await client.get(DATA_ENDPOINT + TABLES.FEE + formula);
      if (fees) {
        dispatch(fetchFeesSuccess(fees));
      } else {
        throw new Error('error while trying to get Fee');
      }
      return fees;
    },
    [dispatch],
  );
};

export const useCreateFees = () => {
  const dispatch = useDispatch();

  return useTypedAsyncFn<Fee[]>(
    async fees => {
      if (fees) {
        const newFees: Fee[] = await client.post(DATA_ENDPOINT + TABLES.FEE, fees);
        if (newFees) {
          dispatch(createFeesSuccess(newFees));
        } else {
          throw new Error('error while trying to create Fee');
        }
        return newFees;
      }
    },
    [dispatch],
  );
};

export const useUpdateFees = () => {
  const dispatch = useDispatch();

  return useTypedAsyncFn<Fee[]>(
    async fees => {
      if (fees) {
        const updatedFees: Fee[] = await client.put(DATA_ENDPOINT + TABLES.FEE, fees);
        if (updatedFees) {
          const updates: Update<Fee>[] = updatedFees.map(updatedFee => ({
            id: updatedFee.id,
            changes: { ...updatedFee },
          }));
          dispatch(updateFeesSuccess(updates));
        } else {
          throw new Error('error while trying to create Fee');
        }
        return updatedFees;
      }
    },
    [dispatch],
  );
};

export const useDeleteFees = () => {
  const dispatch = useDispatch();
  return useTypedAsyncFn<string[]>(
    async feeIds => {
      if (feeIds) {
        await client.delete(DATA_ENDPOINT + TABLES.FEE, feeIds).then(() => {
          dispatch(deleteFeesSuccess(feeIds));
        });
      }
      return [];
    },
    [dispatch],
  );
};

export default { useGetFeesByIds, useCreateFees, useUpdateFees };
