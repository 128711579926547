import { PostgresProject, Project, RawProject } from './typings';

export const serializeProjectForFront = (data: RawProject): Project => ({
  id: data.id,
  name: data.fields.Name,
  type: data.fields.Type,
  projectId: data.fields['Projet ID'],
  clientId: data.fields.Clients && data.fields.Clients[0],
  teamDay: !!data.fields["Journée d'équipe ?"],
  briefLink: data.fields['Brief (Pipedrive)'],
  finished: data.fields['Terminé'],
  standBy: data.fields['Stand-By'],
  seller: data.fields['Vendeur (Auto)'],
  preparationDaysCount: data.fields['# de jours  Préparation (Camille)'],
  totalStaffingDays: data.fields['Somme du nombre de jours (Auto)'],
  alreadyStaffedDays: data.fields['# de journée staffés réservations exclues (Auto)'],
});

export const serializePostgresProjectForFront = (data: PostgresProject): Project => ({
  id: data.id,
  name: data.name,
  type: data.type,
  projectId: data.projet_id,
  clientId: data.clients && data.clients[0],
  teamDay: !!data.journée_déquipe,
  briefLink: data.brief_pipedrive,
  finished: data.terminé,
  standBy: data.stand_by,
  seller: data.vendeur_auto,
  preparationDaysCount:
    data.num_de_jours_préparation_camille === null
      ? undefined
      : Number(data.num_de_jours_préparation_camille),
  totalStaffingDays: Number(data.somme_du_nombre_de_jours_auto),
  alreadyStaffedDays: Number(data.num_de_journée_staffés_réservations_exclues_auto),
});
