import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/types';
import { Place } from './typings';

export type PlaceState = {
  ids: string[];
  entities: Record<string, Place>;
};

export type PlaceAction = PayloadAction<PlaceState>;

const placeAdapter = createEntityAdapter<Place>();

const placeSlice = createSlice({
  name: 'Place',
  initialState: placeAdapter.getInitialState(),
  reducers: {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    fetchPlaceSuccess: placeAdapter.addMany,
  },
});

export const { fetchPlaceSuccess } = placeSlice.actions;

export const placeSelectors = placeAdapter.getSelectors<RootState>(state => state.place);
export default placeSlice.reducer;
