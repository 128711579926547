import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/types';
import { Equipment } from './typings';

export type EquipmentState = {
  ids: string[];
  entities: Record<string, Equipment>;
};

export type EquipmentAction = PayloadAction<EquipmentState>;

const equipmentAdapter = createEntityAdapter<Equipment>();

const equipmentSlice = createSlice({
  name: 'Equipment',
  initialState: equipmentAdapter.getInitialState(),
  reducers: {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    fetchEquipmentSuccess: equipmentAdapter.addMany,
  },
});

export const { fetchEquipmentSuccess } = equipmentSlice.actions;

export const equipmentSelectors = equipmentAdapter.getSelectors<RootState>(
  state => state.equipment,
);
export default equipmentSlice.reducer;
