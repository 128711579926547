import { Planning } from 'module/Planning';
import moment from 'moment';
import { groupBy } from 'lodash';
export const NEW_PLANNING_ID = '-1';

export enum CELL_CATEGORIES {
  //planning
  INVOICED_TRAINING = 'INVOICED_TRAINING',
  INVOICED_CONSULTING = 'INVOICED_CONSULTING',
  COMPANIONSHIP = 'COMPANIONSHIP',
  TEAM_DAY = 'TEAM_DAY',
  DAY_OFF = 'DAY_OFF',
  EMPTY = 'EMPTY',
  // people
  PEOPLE = 'PEOPLE',
  // dates
  PAST = 'PAST',
  TODAY = 'TODAY',
  FUTURE = 'FUTURE',
}

const PLANNING_PROJECT_TYPES = {
  CONSULTING: 'Conseil',
  HOLIDAYS: 'Congés',
  ABSENCE: 'Absence',
  INTERNAL: 'Interne',
  TRAINING: 'Formation',
};

export const getPlanningCategory = (planning: Planning): CELL_CATEGORIES => {
  switch (planning.projectType) {
    case PLANNING_PROJECT_TYPES.CONSULTING:
      if (planning.invoiced) {
        return CELL_CATEGORIES.INVOICED_CONSULTING;
      } else if (planning.companionship) {
        return CELL_CATEGORIES.COMPANIONSHIP;
      }
      return CELL_CATEGORIES.EMPTY;
    case PLANNING_PROJECT_TYPES.TRAINING: {
      if (planning.invoiced) {
        // see https://trello.com/c/GTtT0zgQ:
        // quand c'est de la formation en préparation et facturé > alors c'est la même couleur que le conseil facturé
        // FIXME: use const instead of string for 'Préparation'
        if (planning.interventionType === 'Préparation') {
          return CELL_CATEGORIES.INVOICED_CONSULTING;
        }
        return CELL_CATEGORIES.INVOICED_TRAINING;
      } else if (planning.companionship) {
        return CELL_CATEGORIES.COMPANIONSHIP;
      }
      return CELL_CATEGORIES.EMPTY;
    }
    case PLANNING_PROJECT_TYPES.HOLIDAYS:
    case PLANNING_PROJECT_TYPES.ABSENCE:
      return CELL_CATEGORIES.DAY_OFF;
    case PLANNING_PROJECT_TYPES.INTERNAL:
      return planning.teamDay ? CELL_CATEGORIES.TEAM_DAY : CELL_CATEGORIES.EMPTY;
    default:
      return CELL_CATEGORIES.EMPTY;
  }
};

export const createNewPlanning = (
  peopleId: string,
  date: Date,
  hasPmPlanning = false,
): Planning => ({
  id: NEW_PLANNING_ID,
  peopleId,
  projectId: '',
  staffingDate: date ? moment(date).format('YYYY-MM-DD') : '',
  isAM: date ? moment(date).get('hour') < 12 : false,
  name: '',
  invoiced: false,
  invoicedFreelance: false,
  companionship: false,
  invoicedCompanionship: false,
  details: '',
  teamDay: false,
  specificMaterial: '',
  briefLink: '',
  dayNumber: 0,
  personalConstraints: '',
  hasFees: undefined,
  interventionType: undefined,
  feeList: [],
  place: '',
  workAtHome: false,
  isMerged: date ? moment(date).get('hour') < 12 && !hasPmPlanning : false,
  consultantConfirmation: false,
  consultantValidation: false,
  isReservation: false,
  mealVoucher: undefined,
});

export const groupPlanningByDateAndMember = (
  plannings: Planning[],
): Record<string, Record<string, Planning>> => {
  const planningGroupedByDate = groupBy(
    plannings,
    planning => `${planning.staffingDate}-${planning.isAM ? 'AM' : 'PM'}`,
  );
  const finalResult: Record<string, Record<string, Planning>> = {};
  Object.keys(planningGroupedByDate).forEach(date => {
    planningGroupedByDate[date].forEach(planning => {
      finalResult[date] = { ...finalResult[date], [planning.peopleId]: planning };
    });
  });
  return finalResult;
};

export const getPMPlanning = (
  date: Date,
  peopleId: string,
  plannings: Planning[],
): Planning | undefined => {
  if (!date || !peopleId || !plannings) {
    return;
  }
  const planningByDateAndMember = groupPlanningByDateAndMember(plannings);
  const planningsOnThisPM = planningByDateAndMember[`${moment(date).format('YYYY-MM-DD')}-PM`];
  const PMPlanning = planningsOnThisPM && planningsOnThisPM[peopleId];

  return PMPlanning;
};

// this hooks is given an array of plannings,
// it checks if any of the planning are merged and if it is the case,
// it add the correspondant PM planning to the plannings to update/create depending on the second parameter
export const getAllPlanningFromMergedPlannings = (
  plannings: Planning[],
  isUpdating: boolean,
  allPlannings: Planning[],
): Planning[] => {
  const newPlannings: Planning[] = [];
  // check if two day cells are merged to update or create both of them if needed
  plannings.forEach(planning => {
    if (planning.isMerged) {
      const PMPlanning = getPMPlanning(
        new Date(planning.staffingDate),
        planning.peopleId,
        allPlannings,
      );
      const newPMPlanning = {
        ...planning,
        id: PMPlanning ? PMPlanning.id : NEW_PLANNING_ID,
        isAM: false,
        isMerged: false,
        hasFees: undefined,
      };
      // if the action is an update and there is already a PM planning then we update it
      if (isUpdating && PMPlanning) {
        newPlannings.push(newPMPlanning);
      }
      // if the action is a creation and there is no PM planning then we update it
      if (!isUpdating && !PMPlanning) {
        newPlannings.push(newPMPlanning);
      }
    }
    newPlannings.push(planning);
  });

  return newPlannings;
};
