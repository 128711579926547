import { People, RawPeople } from './typings';

export const serializePeopleForFront = (data: RawPeople): People => ({
  id: data.id,
  lastName: data.fields.Nom,
  firstName: data.fields['Prénom'],
  teams: data.fields['Rôle'],
  email: data.fields.Email,
  subTeamColor: data.fields['Couleur équipe'],
  isAdmin: data.fields['Admin AirTable'],
});
