import { customSavedSelectionPrefix } from './slice';

export const isCustomSavedPeopleFilterType = (peopleFilterType: string) =>
  peopleFilterType.startsWith(customSavedSelectionPrefix);

export const generateCustomSavedPeopleFilterType = (selectionIndex: number) =>
  `${customSavedSelectionPrefix}${selectionIndex}`;

export const extractCustomPeopleSavedSelectionIndex = (peopleFilterType: string) => {
  const indexString = peopleFilterType.split(customSavedSelectionPrefix)[1];
  if (indexString === undefined) return undefined;
  const index = Number(indexString);
  if (isNaN(index)) return undefined;
  return index;
};
