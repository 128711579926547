import { AuthError, AuthResponse } from 'msal';
import Router from 'next/router';
import { useDispatch } from 'react-redux';
import { loginFailure, userLoggedIn, userLoggedOut } from './slice';
import { isCubikMail } from './utils';

type AuthResponseWithExtraInfos =
  | (AuthResponse & {
      userPrincipalName?: string;
    })
  | {
      userPrincipalName?: string;
    };

export const useLogout = () => {
  const dispatch = useDispatch();

  return () => {
    dispatch(userLoggedOut());
    Router.push('/login');
  };
};

export const useLogin = () => {
  const dispatch = useDispatch();

  return (err?: AuthError | undefined, rawData?: AuthResponseWithExtraInfos | undefined) => {
    if (err && err.errorCode && err.errorMessage) {
      dispatch(loginFailure({ errorMessage: err.errorCode + ' : ' + err.errorMessage }));
    }

    if (rawData) {
      let auth = null;
      if (rawData.userPrincipalName) {
        if (isCubikMail(rawData.userPrincipalName)) {
          auth = { user: rawData.userPrincipalName };
          dispatch(userLoggedIn(auth));
          Router.push('/');
        } else {
          dispatch(
            loginFailure({
              errorMessage:
                'The address you used to sign in is not part of the cubik-partners organisation',
            }),
          );
        }
      } else {
        dispatch(loginFailure({ errorMessage: 'error on login' }));
      }
    }
  };
};

export default { useLogout, useLogin };
