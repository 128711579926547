import { PROJECT_TYPES } from 'module/Project';

export type Planning = {
  id: string;
  peopleId: string;
  projectId?: string;
  staffingDate: string;
  isAM: boolean;
  name?: string;
  projectType?: PROJECT_TYPES;
  projectName?: string;
  personalConstraints?: string;
  companionship?: boolean;
  invoicedCompanionship?: boolean;
  interventionType?: string;
  invoiced?: boolean;
  invoicedFreelance?: boolean;
  details?: string;
  teamDay?: boolean;
  dayNumber?: number;
  place?: string;
  specificMaterial?: string;
  briefLink?: string;
  workAtHome?: boolean;
  isMerged: boolean;
  consultantConfirmation: boolean;
  consultantValidation: boolean;
  hasFees?: string;
  feeList: string[];
  isReservation?: boolean;
  isConflict?: boolean;
  conflictingProjectId?: string;
  seller?: string;
  mealVoucher?: string;
  billed?: boolean;
};

export enum DAY_TIME {
  AM = 'AM',
  PM = 'PM',
}

export type PlanningFields = {
  'Date où il est staffé': string;
  People: string[];
  Projet?: string[];
  'AM / PM': DAY_TIME;
  Name?: string;
  'Facturé ?'?: boolean;
  'Compagnonnage ? (Conseil)'?: boolean;
  'Compagnonnage - Freelance Facturé'?: boolean;
  "Type d'intervention (Formation)"?: string;
  'Type de projet'?: [PROJECT_TYPES];
  'Numéro de semaine'?: number;
  'Nom du projet (app)'?: string;
  'Contraintes personnelles'?: string;
  Détails?: string;
  "Journée d'équipe ?"?: [boolean];
  'Numero de la journée (Formation)'?: number;
  'Lieu (Formation)'?: string;
  'Materiel spécifique (Formation)'?: string;
  'Brief (Pipedrive)'?: string[];
  'Work at home'?: boolean;
  Mergé?: boolean;
  'Confirmé consultant': boolean;
  'Validation timekeeping': boolean;
  Frais?: string;
  'Liste des Frais': string[];
  Réservation?: boolean;
  Conflit?: boolean;
  'Projet en conflit'?: string[];
  Vendeur?: string[];
  'Déj payé par Client/Cubik'?: string;
  'Etat de Facturation'?: boolean;
  'Facturé freelance'?: boolean;
};

export type RawPlanning = {
  id: string;
  fields: PlanningFields;
};

export type PostgresPlanning = {
  id: string;
  people: string[];
  projet: string[];
  date_où_il_est_staffé: string;
  am_pm: string;
  name: string;
  type_dintervention_formation: string;
  compagnonnage_conseil: string;
  compagnonnage_freelance_facturé: string;
  type_de_projet: PROJECT_TYPES[];
  nom_du_projet_app: string;
  contraintes_personnelles: string;
  facturé: boolean;
  détails: string;
  journée_déquipe: [boolean];
  numero_de_la_journée_formation: string;
  lieu_formation: string;
  materiel_spécifique_formation: string;
  brief_pipedrive: string[];
  work_at_home: boolean;
  mergé: boolean;
  confirmé_consultant: boolean;
  validation_timekeeping: boolean;
  frais: string;
  liste_des_frais: string[];
  réservation: boolean;
  conflit: boolean;
  projet_en_conflit: string[];
  vendeur: string[];
  déj_payé_par_client_cubik: string;
  etat_de_facturation: boolean;
  facturé_freelance: boolean;
};

export type StaffedWithMeResponseType = Pick<Planning, 'id' | 'peopleId' | 'isAM' | 'isMerged'>[];
