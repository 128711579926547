import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/types';
import { Team } from './typings';

export type TeamsState = {
  ids: string[];
  entities: Record<string, Team>;
};

export type TeamsAction = PayloadAction<TeamsState>;

const teamAdapter = createEntityAdapter<Team>();

const teamSlice = createSlice({
  name: 'Team',
  initialState: teamAdapter.getInitialState(),
  reducers: {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    fetchTeamsSuccess: teamAdapter.addMany,
  },
});

export const { fetchTeamsSuccess } = teamSlice.actions;

export const teamsSelectors = teamAdapter.getSelectors<RootState>(state => state.teams);
export default teamSlice.reducer;
