import { Planning } from 'module/Planning';
import { useDispatch, useSelector } from 'react-redux';
import { ModalData, ModalState, useToggleModal } from 'module/Modal';
import { useTypedAsyncFn, useTypedAsyncFnTwoParams } from 'redux/useTypedAsyncFn';
import client, { DATA_ENDPOINT } from 'services/networking/client';
import { TABLES } from 'module/serializer';
import { Update } from '@reduxjs/toolkit';
import moment from 'moment';
import { Dispatch } from 'react';
import { isCustomSavedPeopleFilterType, PeopleFilterTypes } from 'module/Filter';
import { RootState } from 'redux/types';
import { useIsEditionBlocked } from 'module/UI';
import { getIsLoggedInUserAdmin, getIsLoggedInUserTeamLeader } from 'module/Login';
import { projectSelectors, useUpdateProjectStaffedDays } from 'module/Project';
import {
  getStaffedDays,
  shouldConsiderPlanningAsStaffedRegardingBudget,
  shouldShowNoBudgetAlert,
} from 'module/Project/utils';
import { useIntl } from 'react-intl';
import { decompressJSON } from 'services/networking/jsonCompressor';
import {
  copiedPlanningSelector,
  createPlanningSuccess,
  deletePlanningSuccess,
  fetchPlanningSuccess,
  isCuttingSelector,
  planningSelectors,
  updateCopiedPlanning,
  updateCutPlanning,
  updatePlanningSuccess,
  upsertPlanning,
} from './slice';
import {
  createNewPlanning,
  getAllPlanningFromMergedPlannings,
  getPMPlanning,
  NEW_PLANNING_ID,
} from './utils';
import { StaffedWithMeResponseType } from './typings';

const EDITABLE_PAST_DAYS_LIMIT = 15;
const EDITABLE_PAST_DAYS_LIMIT_IN_MS = EDITABLE_PAST_DAYS_LIMIT * 24 * 60 * 60 * 1000;

// HACK: We need to queue some actions dispatch to be replayed in order to avoid a bug.
// The bug is when planning refresh ends and erase last modifications
// ex: Refresh planning call start -> Add planning call -> Add planing redux action -> Refresh planning action
// The right thing to do but too costly at the time should be using a middleware like redux-saga
let isLoading = false;
let queue: any[] = [];
export const useQueuedDispatch = (): [Dispatch<any>, () => void, () => void] => {
  const dispatch = useDispatch();

  const startLoading = () => {
    isLoading = true;
  };
  const stopLoading = () => {
    isLoading = false;
    queue.forEach(action => {
      dispatch(action);
    });
    queue = [];
  };
  const queuedDispatch = (action: any) => {
    if (isLoading) {
      dispatch(action);
      queue = [...queue, action];
    } else dispatch(action);
  };

  return [queuedDispatch, startLoading, stopLoading];
};

export const useGetPlanning = () => {
  const dispatch = useDispatch();
  return useTypedAsyncFn<string>(
    async (id: string) => {
      const planning: Planning = await client.get(DATA_ENDPOINT + TABLES.PLANNING + '?id=' + id);
      if (planning) {
        dispatch(upsertPlanning(planning));
      } else {
        throw new Error('error while trying to get Planning');
      }
      return planning;
    },
    [dispatch],
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const buildPlanningAirtableQueryParams = (
  startDate: any,
  endDate: any,
  peopleFilterType: string,
  userEmail: string,
  customPeopleFilter: any,
) => {
  const formattedStartDate = moment(startDate).subtract(1, 'days').format('YYYY-MM-DD');
  const formattedEndDate = moment(endDate).add(1, 'days').format('YYYY-MM-DD');

  let peopleFilterTypeForQuery = peopleFilterType;

  if (isCustomSavedPeopleFilterType(peopleFilterType))
    peopleFilterTypeForQuery = PeopleFilterTypes.Custom;

  let peopleFormula = 'OR(';

  switch (peopleFilterTypeForQuery) {
    case PeopleFilterTypes.MeOnly:
      peopleFormula += `{Email Cubik}="${userEmail}")`;
      break;

    case PeopleFilterTypes.Custom:
      customPeopleFilter.forEach((id: string, index: number) => {
        peopleFormula += `{ID Cubik}="${id}"`;
        if (index < customPeopleFilter.length - 1) peopleFormula += ',';
      });
      peopleFormula += ')';
      break;

    default:
      peopleFormula = '';
      break;
  }

  if (peopleFormula !== '') {
    peopleFormula = `, ${peopleFormula}`;
  }

  return `?filterByFormula=AND(IS_AFTER({Date où il est staffé},'${formattedStartDate}'), IS_BEFORE({Date où il est staffé},'${formattedEndDate}')${peopleFormula})`;
};

const buildPlanningSyncIncQueryParams = (
  startDate: any,
  endDate: any,
  peopleFilterType: string,
  userEmail: string,
  customPeopleFilter: any,
) => {
  const formattedStartDate = moment(startDate).subtract(1, 'days').format('YYYY-MM-DD');
  const formattedEndDate = moment(endDate).add(1, 'days').format('YYYY-MM-DD');

  let peopleFilterTypeForQuery = peopleFilterType;

  if (isCustomSavedPeopleFilterType(peopleFilterType))
    peopleFilterTypeForQuery = PeopleFilterTypes.Custom;

  let peopleFormula = `&peopleFilterType=${peopleFilterTypeForQuery}`;

  switch (peopleFilterTypeForQuery) {
    case PeopleFilterTypes.MeOnly:
      peopleFormula += `&userEmail=${userEmail}`;
      break;

    case PeopleFilterTypes.Custom:
      peopleFormula += `&cubikIds=${JSON.stringify(customPeopleFilter)}`;
      break;

    default:
      break;
  }

  return `?startDate=${formattedStartDate}&endDate=${formattedEndDate}${peopleFormula}`;
};

export const useGetPlannings = () => {
  const [, startLoading, stopLoading] = useQueuedDispatch();
  const dispatch = useDispatch();

  const peopleFilterType = useSelector((store: RootState) => store.filter.peopleFilterType);
  const customPeopleFilter = useSelector((state: RootState) => state.filter.customPeopleFilter);
  const userEmail = useSelector((state: RootState) => state.login.user);

  return useTypedAsyncFnTwoParams<Date, Date>(
    async (startDate, endDate) => {
      startLoading();

      // TODO: Allow both data source
      //const queryParams = buildPlanningAirtableQueryParams(startDate, endDate, peopleFilterType, userEmail, customPeopleFilter)
      const queryParams = buildPlanningSyncIncQueryParams(
        startDate,
        endDate,
        peopleFilterType,
        userEmail,
        customPeopleFilter,
      );

      const plannings: Planning[] = decompressJSON(
        await client.get(DATA_ENDPOINT + TABLES.PLANNING + queryParams),
      );

      if (plannings) {
        dispatch(fetchPlanningSuccess(plannings));
        stopLoading();
      } else {
        throw new Error('error while trying to get Planning');
      }
      return plannings;
    },
    [dispatch, peopleFilterType, customPeopleFilter],
  );
};

export const useCreatePlannings = () => {
  const [dispatch] = useQueuedDispatch();
  const toggleModal = useToggleModal();

  return useTypedAsyncFn<ModalState['planning'][]>(
    async plannings => {
      if (plannings && plannings.length > 0) {
        const newPlannings: Planning[] = await client.post(
          DATA_ENDPOINT + TABLES.PLANNING,
          plannings,
        );
        if (newPlannings) {
          dispatch(createPlanningSuccess(newPlannings));
          toggleModal(false);
        } else {
          throw new Error('error while trying to create Planning');
        }
        return newPlannings;
      }
    },
    [dispatch],
  );
};

export const useUpdatePlannings = () => {
  const [dispatch] = useQueuedDispatch();
  const toggleModal = useToggleModal();

  return useTypedAsyncFn<ModalState['planning'][]>(
    async plannings => {
      if (plannings && plannings.length > 0) {
        const updatedPlannings: Planning[] = await client.put(
          DATA_ENDPOINT + TABLES.PLANNING,
          plannings,
        );
        if (updatedPlannings) {
          const updates: Update<Planning>[] = updatedPlannings.map(updatedPlanning => ({
            id: updatedPlanning.id,
            changes: { ...updatedPlanning },
          }));
          dispatch(updatePlanningSuccess(updates));
          toggleModal(false);
        } else {
          throw new Error('error while trying to update Planning');
        }
        return updatedPlannings;
      }
    },
    [dispatch],
  );
};

export const useDeletePlanning = () => {
  const [dispatch] = useQueuedDispatch();
  const allPlannings = useSelector(planningSelectors.selectAll);
  const { updateProjectStaffedDays } = useUpdateProjectStaffedDays();

  return useTypedAsyncFn<Planning>(
    async planning => {
      if (planning) {
        const planningToDeleteIds = [planning.id];
        // add PMPlanning to deletion if merged
        const PMPlanning = getPMPlanning(
          new Date(planning.staffingDate),
          planning.peopleId,
          allPlannings,
        );
        planning.isMerged &&
          PMPlanning &&
          PMPlanning.id !== NEW_PLANNING_ID &&
          PMPlanning.id !== planning.id &&
          planningToDeleteIds.push(PMPlanning.id);

        await client.delete(DATA_ENDPOINT + TABLES.PLANNING, planningToDeleteIds).then(() => {
          dispatch(deletePlanningSuccess(planningToDeleteIds));
        });

        const deletedDays = planningToDeleteIds.length / 2;
        if (shouldConsiderPlanningAsStaffedRegardingBudget(planning)) {
          updateProjectStaffedDays(planning.projectId as string, -deletedDays);
        }
      }
    },
    [dispatch],
  );
};

// this hooks returns a function
export const useUpdateOrCreatePlanningOnCondition = (): [
  boolean,
  (args: {
    existingPlanning: Planning;
    conditionToUpdate: boolean;
    planningToCreateOrUpdate: Planning;
    ignoreBudgetAlert?: boolean;
  }) => Promise<Planning[]>,
] => {
  const intl = useIntl();
  const [createPlanningData, createPlannings] = useCreatePlannings();
  const [updatePlanningData, updatePlannings] = useUpdatePlannings();
  const allPlannings = useSelector(planningSelectors.selectAll);
  const { updateProjectStaffedDays } = useUpdateProjectStaffedDays();
  const projects = useSelector(projectSelectors.selectAll);

  const loading = !!createPlanningData.loading || !!updatePlanningData.loading;

  return [
    loading,
    async ({
      existingPlanning,
      conditionToUpdate,
      planningToCreateOrUpdate,
      ignoreBudgetAlert,
    }) => {
      const selectedProject = projects.find(({ id }) => planningToCreateOrUpdate.projectId === id);

      if (
        !ignoreBudgetAlert &&
        shouldShowNoBudgetAlert(existingPlanning, planningToCreateOrUpdate, selectedProject)
      ) {
        const continueAnyway = confirm(intl.formatMessage({ id: 'staffingForm.noBudgetAlert' }));
        if (!continueAnyway) return [];
      }

      const staffedDays = getStaffedDays(existingPlanning);

      const plannings: Planning[] = conditionToUpdate
        ? await updatePlannings(
            getAllPlanningFromMergedPlannings([planningToCreateOrUpdate], true, allPlannings),
          )
        : await createPlannings(
            getAllPlanningFromMergedPlannings([planningToCreateOrUpdate], false, allPlannings),
          );

      if (shouldConsiderPlanningAsStaffedRegardingBudget(existingPlanning)) {
        updateProjectStaffedDays(existingPlanning.projectId as string, -staffedDays);
      }
      if (shouldConsiderPlanningAsStaffedRegardingBudget(planningToCreateOrUpdate)) {
        updateProjectStaffedDays(planningToCreateOrUpdate.projectId as string, staffedDays);
      }

      return plannings;
    },
  ];
};

export const usePastePlanning = (): [boolean, (modalData: ModalData) => void] => {
  const copiedPlanning = useSelector(copiedPlanningSelector) as Planning;
  const isCutting = useSelector(isCuttingSelector);
  const [loading, updateOrCreatePlanningOnCondition] = useUpdateOrCreatePlanningOnCondition();
  const [{ loading: deleteLoading }, deletePlannings] = useDeletePlanning();

  return [
    loading || deleteLoading,
    (modalData: ModalData) => {
      if (!modalData.date || !modalData.peopleData || !copiedPlanning.id) {
        return;
      }
      const isAM = moment(modalData.date).get('hour') < 12;
      const oldPlanning = modalData.planning;

      const newPlanning = {
        ...copiedPlanning,
        id:
          oldPlanning && oldPlanning.id && oldPlanning.id !== NEW_PLANNING_ID
            ? oldPlanning.id
            : NEW_PLANNING_ID,
        staffingDate: moment(modalData.date).format('YYYY-MM-DD'),
        isAM,
        peopleId: modalData.peopleData.id,
        isMerged: (copiedPlanning.isMerged && isAM) || (oldPlanning ? oldPlanning.isMerged : isAM),
        personalConstraints: oldPlanning.personalConstraints,
        hasFees: oldPlanning.hasFees,
        feeList: oldPlanning.feeList,
        consultantValidation: false,
        consultantConfirmation: false,
        workAtHome: oldPlanning.workAtHome,
        mealVoucher: oldPlanning.mealVoucher,
      };

      updateOrCreatePlanningOnCondition({
        existingPlanning: oldPlanning,
        conditionToUpdate: newPlanning.id !== NEW_PLANNING_ID,
        planningToCreateOrUpdate: newPlanning,
        ignoreBudgetAlert: isCutting,
      });
      if (isCutting) {
        deletePlannings(copiedPlanning);
      }
    },
  ];
};

export const useMergePlannings = (): [boolean, (modalData: ModalData) => void] => {
  const [createPlanningData, createPlannings] = useCreatePlannings();
  const [updatePlanningData, updatePlannings] = useUpdatePlannings();
  const allPlannings = useSelector(planningSelectors.selectAll);

  const loading = !!createPlanningData.loading || !!updatePlanningData.loading;

  return [
    loading,
    (modalData: ModalData) => {
      const { date, peopleData, planning } = modalData;
      if (date && peopleData) {
        const planningsToCreate = [];
        const planningsToUpdate = [];
        // compute AM planning
        const amPlanningExists = !!planning && planning.id !== NEW_PLANNING_ID;
        if (amPlanningExists) {
          planningsToUpdate.push({ ...planning, isMerged: true });
        } else {
          planningsToCreate.push({ ...createNewPlanning(peopleData.id, date), isMerged: true });
        }

        // compute PM planning
        const PMPlanning = getPMPlanning(
          new Date(planning.staffingDate),
          planning.peopleId,
          allPlannings,
        );

        const newPMPlanning = {
          ...planning,
          id: PMPlanning ? PMPlanning.id : NEW_PLANNING_ID,
          isAM: false,
          isMerged: false,
          hasFees: undefined,
          feeList: [],
        };

        if (newPMPlanning.id !== NEW_PLANNING_ID) {
          planningsToUpdate.push(newPMPlanning);
        } else {
          planningsToCreate.push(newPMPlanning);
        }

        return Promise.all([
          updatePlannings(planningsToUpdate),
          createPlannings(planningsToCreate),
        ]);
      }
    },
  ];
};

export const useUnmergePlannings = (): [boolean, (planning: Planning) => void] => {
  const [updatePlanningData, updatePlannings] = useUpdatePlannings();

  return [
    updatePlanningData.loading,
    (planning: Planning) => {
      if (planning) {
        updatePlannings([{ ...planning, isMerged: false }]);
      }
    },
  ];
};

export const useUpdateCopiedPlanning = (): ((planning: Planning | undefined) => void) => {
  const [dispatch] = useQueuedDispatch();
  return planning => {
    if (!planning) {
      return;
    }
    dispatch(updateCopiedPlanning(planning));
  };
};

export const useUpdateCutPlanning = (): ((planning: Planning | undefined) => void) => {
  const [dispatch] = useQueuedDispatch();
  return planning => {
    if (!planning) {
      return;
    }
    dispatch(updateCutPlanning(planning));
  };
};

export const useGetStaffedWithMeProjects = () => {
  return useTypedAsyncFn<{
    peopleId: string;
    projectId: string;
    staffingDate: string;
    isMerged: boolean;
    isAM: boolean;
  }>(async ({ peopleId, projectId, staffingDate, isMerged, isAM }) => {
    const plannings = (await client.get(
      `${DATA_ENDPOINT}staffed-with-me?peopleId=${peopleId}&projectId=${projectId}&staffingDate=${staffingDate}&isMerged=${
        isMerged ? '1' : '0'
      }&isAM=${isAM ? '1' : '0'}`,
    )) as StaffedWithMeResponseType;

    return plannings;
  }, []);
};

export const useIsPlanningEditionBlocked = (planning?: Planning) => {
  const isEditionBlocked = useIsEditionBlocked();
  const isLoggedInUserLeader = useSelector(getIsLoggedInUserTeamLeader);
  const isLoggedInUserAdmin = useSelector(getIsLoggedInUserAdmin);

  // The planning can't be edited if:
  // - the edition is blocked
  // - the staffing date is older than EDITABLE_PAST_DAYS_LIMIT days (unless the user is an AirTable Admin)
  // - the Timekeeping is validation is true (unless the user is a team leader)
  // - The billing state (column "Etat de facturation" in AirTable) is set
  return (
    isEditionBlocked ||
    planning === undefined ||
    (+new Date() - +new Date(planning.staffingDate) > EDITABLE_PAST_DAYS_LIMIT_IN_MS &&
      !isLoggedInUserAdmin) ||
    (planning.consultantValidation && !isLoggedInUserLeader) ||
    !!planning.billed
  );
};
