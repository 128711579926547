export enum FeeType {
  empty = '',
  food = 'Repas',
  train = 'Train',
  flight = 'Avion',
  metroTram = 'Métro/Tram',
  taxi = 'Taxi',
  accomodation = 'Hôtel/Logement',
  fossilFuelCarRental = 'Location de voiture thermique',
  electricCarRental = 'Location de voiture électrique',
  tollFee = 'Péages',
  parkingFee = 'Parking',
  gas = 'Essence',
  gasoil = 'Gasoil',
  furniture = 'Fournitures',
  mileageAllowances = 'Indemnités kilométriques',
  other = 'Autres',
}

type FeeFields = {
  Descriptif: string;
  Type: FeeType;
  TVA: number;
  Total: number;
  Planning: string[];
  'Distance (en km)': number | void;
  'Volume (en L)': number | void;
  'Aller-Retour': boolean | void;
  'Nombre de Nuitées': number | void;
};

export type RawFee = {
  id: string;
  fields: FeeFields;
};

type BaseFee = {
  id: string;
  description: string;
  tva: number;
  total: number;
  planningId: string;
};

type DistanceFee = {
  distanceKm: number;
};

type CommonFee = BaseFee & {
  type: Exclude<
    FeeType,
    | FeeType.flight
    | FeeType.train
    | FeeType.taxi
    | FeeType.fossilFuelCarRental
    | FeeType.electricCarRental
    | FeeType.mileageAllowances
    | FeeType.accomodation
    | FeeType.gas
    | FeeType.gasoil
  >;
};

type TrainFlightFee = BaseFee &
  DistanceFee & {
    type: FeeType.flight | FeeType.train;
    roundtrip: boolean;
  };

type TaxiFee = BaseFee &
  DistanceFee & {
    type: FeeType.taxi;
  };

type CarRentalFee = BaseFee &
  DistanceFee & {
    type: FeeType.fossilFuelCarRental | FeeType.electricCarRental;
  };

type MileageAllowanceFee = BaseFee &
  DistanceFee & {
    type: FeeType.mileageAllowances;
  };

type AccomodationFee = BaseFee & {
  type: FeeType.accomodation;
  numberOfNights: number;
};

type GasGasoilFee = BaseFee & {
  type: FeeType.gas | FeeType.gasoil;
  volumeL: number;
};

export type Fee =
  | CommonFee
  | TrainFlightFee
  | TaxiFee
  | CarRentalFee
  | MileageAllowanceFee
  | AccomodationFee
  | GasGasoilFee;
