import { createSelector } from '@reduxjs/toolkit';
import { peopleSelectors } from 'module/People/slice';
import { RootState } from 'redux/types';
import moment from 'moment';

const getPeopleEntities = peopleSelectors.selectEntities;

export const getWorkAtHomeFilterStatus = (store: RootState) => store.filter.workAtHome;
export const getInvoicedFilterStatus = (store: RootState) => store.filter.invoiced;
export const getValidationTimekeepingStatus = (store: RootState) =>
  store.filter.validationTimekeeping;
export const getPeopleFilterType = (store: RootState) => store.filter.peopleFilterType;
const getCustomPeopleFilter = (store: RootState) => store.filter.customPeopleFilter;
const getCustomPeopleSavedSelection = (store: RootState) =>
  store.filter.customPeopleSavedSelections;

export const getValidCustomPeopleFilter = createSelector(
  [getCustomPeopleFilter, getPeopleEntities],
  (customPeopleFilter, peopleEntities) => {
    return customPeopleFilter.filter(id => peopleEntities[id] !== undefined);
  },
);

export const getValidCustomSavedSelections = createSelector(
  [getCustomPeopleSavedSelection, getPeopleEntities],
  (customPeopleSavedSelections, peopleEntities) => {
    return customPeopleSavedSelections.map(({ name, people }) => ({
      name,
      people: people.filter(id => peopleEntities[id] !== undefined),
    }));
  },
);

export const getPlanningCellDatesList = (startDate: Date, endDate: Date) => {
  const now = moment(startDate).clone();
  const dates = [];

  // add a day to the end date to include the last day:
  const endDateAdjusted = moment(endDate).add(1, 'days');

  while (now.isSameOrBefore(endDateAdjusted)) {
    if (now.isoWeekday() !== 6 && now.isoWeekday() !== 7) {
      // 9 and  14 are arbitrary hours, they can be set to another value.
      // The goal is just to know if its in the morning or in the afternoon (< ou > a 12h)
      dates.push(now.set('hour', 9).format());
      dates.push(now.set('hour', 14).format());
    }
    now.add(1, 'days');
  }

  return dates;
};

export const getStartDate = (store: RootState): Date => store.filter.startDate;
export const getEndDate = (store: RootState): Date => store.filter.endDate;
export const getGridDates = (store: RootState): string[] => {
  const {
    filter: { startDate, endDate },
  } = store;

  return getPlanningCellDatesList(startDate, endDate);
};
