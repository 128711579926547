import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { FILTERS_LOCAL_STORAGE_KEYS } from './constants';

export enum Filters {
  workAtHome = 'workAtHome',
  validationTimekeeping = 'validationTimekeeping',
  invoiced = 'invoiced',
  peopleFilterType = 'peopleFilterType',
  customPeopleFilter = 'customPeopleFilter',
}

export enum PeopleFilterTypes {
  MeOnly = 'MeOnly',
  Everybody = 'Everybody',
  Custom = 'Custom',
}

export const customSavedSelectionPrefix = 'CustomSavedSelection-';

export type FilterState = {
  workAtHome: boolean;
  validationTimekeeping: boolean;
  invoiced: boolean;
  peopleFilterType: string; // type PeopleFilterTypes | `CustomSavedSelection-${number}`
  customPeopleSavedSelections: { name: string; people: string[] }[];
  customPeopleFilter: string[];
  startDate: Date;
  endDate: Date;
};

export type FilterAction = PayloadAction<boolean>;

const initialState: FilterState = {
  workAtHome: false,
  validationTimekeeping: false,
  invoiced: false,
  peopleFilterType: PeopleFilterTypes.MeOnly,
  customPeopleFilter: [],
  customPeopleSavedSelections: [],
  startDate: moment().subtract(7, 'days').startOf('isoWeek').toDate(),
  endDate: moment().add(2, 'month').startOf('isoWeek').toDate(),
};

const FilterSlice = createSlice({
  name: 'Filter',
  initialState,
  reducers: {
    updateWorkAtHome: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem(FILTERS_LOCAL_STORAGE_KEYS.WORK_AT_HOME, JSON.stringify(action.payload));
      state.workAtHome = action.payload;
    },
    updateValidationTimekeeping: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem(
        FILTERS_LOCAL_STORAGE_KEYS.VALIDATION_TIMEKEEPING,
        JSON.stringify(action.payload),
      );
      state.validationTimekeeping = action.payload;
    },
    updateInvoiced: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem(FILTERS_LOCAL_STORAGE_KEYS.INVOICED, JSON.stringify(action.payload));
      state.invoiced = action.payload;
    },
    updatePeopleFilterType: (state, action: PayloadAction<string>) => {
      state.peopleFilterType = action.payload;
    },
    updateCustomPeopleFilter: (state, action: PayloadAction<string[]>) => {
      state.customPeopleFilter = action.payload;
    },
    updateCustomPeopleSavedSelections: (
      state,
      action: PayloadAction<{ name: string; people: string[] }[]>,
    ) => {
      state.customPeopleSavedSelections = action.payload;
      localStorage.setItem(
        FILTERS_LOCAL_STORAGE_KEYS.CUSTOM_PEOPLE_SAVED_SELECTION,
        JSON.stringify(action.payload),
      );
    },
    updateFilters: (state, action: PayloadAction<any>) => {
      // eslint-disable-next-line no-prototype-builtins
      if (action.payload.hasOwnProperty('peopleFilterType')) {
        localStorage.setItem(
          FILTERS_LOCAL_STORAGE_KEYS.PEOPLE_FILTER_TYPE,
          JSON.stringify(action.payload.peopleFilterType),
        );
      }
      // eslint-disable-next-line no-prototype-builtins
      if (action.payload.hasOwnProperty('customPeopleFilter')) {
        localStorage.setItem(
          FILTERS_LOCAL_STORAGE_KEYS.CUSTOM_PEOPLE_FILTER,
          JSON.stringify(action.payload.customPeopleFilter),
        );
      }
      return { ...state, ...action.payload };
    },
    updateStartDate: (state, action: PayloadAction<FilterState['startDate']>) => {
      state.startDate = action.payload;
    },
    updateEndDate: (state, action: PayloadAction<FilterState['endDate']>) => {
      state.endDate = action.payload;
    },
  },
});

export const {
  updateWorkAtHome,
  updateValidationTimekeeping,
  updateInvoiced,
  updatePeopleFilterType,
  updateCustomPeopleFilter,
  updateCustomPeopleSavedSelections,
  updateFilters,
  updateStartDate,
  updateEndDate,
} = FilterSlice.actions;

export default FilterSlice.reducer;
