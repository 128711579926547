import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/types';
import { Client } from './typings';

export type ClientState = {
  ids: string[];
  entities: Record<string, Client>;
};

export type ClientAction = PayloadAction<ClientState>;

const clientAdapter = createEntityAdapter<Client>();

const clientSlice = createSlice({
  name: 'Client',
  initialState: clientAdapter.getInitialState(),
  reducers: {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    fetchClientSuccess: clientAdapter.addMany,
  },
});

export const { fetchClientSuccess } = clientSlice.actions;

export const clientSelectors = clientAdapter.getSelectors<RootState>(state => state.client);
export default clientSlice.reducer;
