import client, { DATA_ENDPOINT } from 'services/networking/client';
import { useDispatch } from 'react-redux';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import { TABLES } from 'module/serializer';
import { fetchTeamsSuccess } from './slice';
import { Team } from './typings';

export const useGetTeams = () => {
  const dispatch = useDispatch();

  return useTypedAsyncFn<void>(async () => {
    const teams: Team[] = await client.get(DATA_ENDPOINT + TABLES.TEAM);
    if (teams) {
      dispatch(fetchTeamsSuccess(teams));
    } else {
      throw new Error('error while trying to get Team');
    }
    return teams;
  }, [dispatch]);
};

export default { useGetTeams };
