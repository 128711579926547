import client, { DATA_ENDPOINT } from 'services/networking/client';
import { useDispatch } from 'react-redux';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import { TABLES } from 'module/serializer';
import { fetchClientSuccess } from './slice';
import { Client } from './typings';

export const useGetClients = () => {
  const dispatch = useDispatch();

  return useTypedAsyncFn<void>(async () => {
    const clients: Client[] = await client.get(DATA_ENDPOINT + TABLES.CLIENT);
    if (clients) {
      dispatch(fetchClientSuccess(clients));
    } else {
      throw new Error('error while trying to get Client');
    }
    return clients;
  }, [dispatch]);
};

export default { useGetClients };
