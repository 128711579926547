import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/types';
import { Planning } from './typings';

export type PlanningState = {
  ids: string[];
  entities: Record<string, Planning>;
  copiedPlanning?: Planning;
  isCutting: boolean;
  isExporting: boolean;
};

export type PlanningAction = PayloadAction<PlanningState>;

const planningAdapter = createEntityAdapter<Planning>();

const planningSlice = createSlice({
  name: 'Planning',
  initialState: {
    ...planningAdapter.getInitialState(),
    copiedPlanning: {} as Planning | undefined,
    isCutting: false,
    isExporting: false,
  },
  reducers: {
    /* eslint-disable @typescript-eslint/unbound-method */
    upsertPlanning: planningAdapter.upsertOne,
    fetchPlanningSuccess: planningAdapter.setAll,
    createPlanningSuccess: planningAdapter.addMany,
    updatePlanningSuccess: planningAdapter.updateMany,
    deletePlanningSuccess: planningAdapter.removeMany,
    /* eslint-enable @typescript-eslint/unbound-method */
    updateCopiedPlanning: (state, action: PayloadAction<PlanningState['copiedPlanning']>) => {
      if (action.payload) {
        state.copiedPlanning = action.payload;
        state.isCutting = false;
      }
    },
    updateCutPlanning: (state, action: PayloadAction<PlanningState['copiedPlanning']>) => {
      if (action.payload) {
        state.copiedPlanning = action.payload;
        state.isCutting = true;
      }
    },
    updateExporting: (state, action: PayloadAction<boolean>) => {
      state.isExporting = action.payload;
    },
  },
});

export const {
  upsertPlanning,
  fetchPlanningSuccess,
  createPlanningSuccess,
  updatePlanningSuccess,
  deletePlanningSuccess,
  updateCopiedPlanning,
  updateCutPlanning,
  updateExporting,
} = planningSlice.actions;

export const planningSelectors = planningAdapter.getSelectors<RootState>(state => state.planning);
export const copiedPlanningSelector = (state: RootState) => state.planning.copiedPlanning;
export const isCuttingSelector = (state: RootState) => state.planning.isCutting;
export default planningSlice.reducer;
