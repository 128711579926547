import { Planning } from 'module/Planning';
import { Project } from './typings';

type NeededPlanningAttributesForBudget = Pick<Planning, 'projectId' | 'isReservation' | 'invoiced'>;

export const getStaffedDays = ({ isMerged }: { isMerged: boolean }) => (isMerged ? 1 : 0.5);

export const shouldConsiderPlanningAsStaffedRegardingBudget = (
  planning: NeededPlanningAttributesForBudget,
) => !!planning.projectId && !planning.isReservation && !!planning.invoiced;

export const shouldShowNoBudgetAlert = (
  existingPlanning: NeededPlanningAttributesForBudget & Pick<Planning, 'isMerged'>,
  newPlanning: NeededPlanningAttributesForBudget,
  selectedProject?: Project,
) =>
  selectedProject &&
  !shouldConsiderPlanningAsStaffedRegardingBudget(existingPlanning) &&
  shouldConsiderPlanningAsStaffedRegardingBudget(newPlanning) &&
  selectedProject.totalStaffingDays > 0 &&
  selectedProject.alreadyStaffedDays + getStaffedDays(existingPlanning) >
    selectedProject.totalStaffingDays;
