import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsEditionBlocked } from './selectors';
import { toggleIsEditionBlocked } from './slice';

export const useToggleEditionBlocked = () => {
  const dispatch = useDispatch();
  return React.useCallback(() => dispatch(toggleIsEditionBlocked()), [dispatch]);
};

export const useIsEditionBlocked = () => useSelector(getIsEditionBlocked);
