import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/types';
import { Project } from '.';

export type ProjectState = {
  ids: string[];
  entities: Record<string, Project>;
};

export type ProjectAction = PayloadAction<ProjectState>;

const projectAdapter = createEntityAdapter<Project>();

const projectSlice = createSlice({
  name: 'Project',
  initialState: projectAdapter.getInitialState(),
  reducers: {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    fetchProjectSuccess: projectAdapter.setAll,
    updateProjectStaffedDays: (
      state,
      {
        payload: { projectId, daysToAddOrRemove },
      }: PayloadAction<{ projectId: string; daysToAddOrRemove: number }>,
    ) => {
      const existingStaffedDays = state.entities[projectId]?.alreadyStaffedDays;
      if (existingStaffedDays === undefined) return;
      return projectAdapter.updateOne(state, {
        id: projectId,
        changes: { alreadyStaffedDays: existingStaffedDays + daysToAddOrRemove },
      });
    },
  },
});

export const { fetchProjectSuccess, updateProjectStaffedDays } = projectSlice.actions;

export const projectSelectors = projectAdapter.getSelectors<RootState>(state => state.project);
export default projectSlice.reducer;
