/* eslint-disable no-console */
import { captureException } from 'services/sentry';
import { useAsyncFn } from 'react-use';

/**
 * A wrapper around useAsyncFn until its arguments can be properly typed
 * See https://github.com/streamich/react-use/issues/1111
 *
 * @param callback to be called in useAsyncFn
 * @param deps that should trigger recreating the callback
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function useTypedAsyncFn<T>(callback: (input: T) => Promise<any>, deps: any[]) {
  return useAsyncFn(async (...args: T[]) => {
    try {
      return await callback(args[0]);
    } catch (err) {
      console.error('useTypeAsync args : ', JSON.stringify(args));
      captureException(err);
      throw err;
    }
  }, deps);
}

export function useTypedAsyncFnTwoParams<T, V>(
  callback: (arg1: T, arg2: V) => Promise<any>,
  deps: any[],
) {
  return useAsyncFn(async (arg1: T, arg2: V) => {
    try {
      return await callback(arg1, arg2);
    } catch (err) {
      console.error('useTypeAsync args : ', JSON.stringify(arg1), JSON.stringify(arg2));
      captureException(err);
      throw err;
    }
  }, deps);
}
