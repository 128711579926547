import { compress, decompress } from 'compress-json';
import { isDev } from 'utils';

/**
 * we need to compress some responses because AWS Lambda@Edge does not support response size bigger than 1 MB:
 * https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/edge-functions-restrictions.html
 */
export const compressJSON = isDev
  ? (object: object) => object
  : (object: object) => compress(JSON.parse(JSON.stringify(object))); // JSON.parse and JSON.stringify to avoid crash caused by undefined values

export const decompressJSON = isDev ? (object: object) => object : decompress;
