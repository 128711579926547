import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/types';
import { People } from './typings';

export type PeopleState = {
  ids: string[];
  entities: Record<string, People>;
};

export type PeopleAction = PayloadAction<PeopleState>;

const peopleAdapter = createEntityAdapter<People>();

const peopleSlice = createSlice({
  name: 'People',
  initialState: peopleAdapter.getInitialState(),
  reducers: {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    fetchPeopleSuccess: peopleAdapter.addMany,
  },
});

export const { fetchPeopleSuccess } = peopleSlice.actions;

export const peopleSelectors = peopleAdapter.getSelectors<RootState>(state => state.people);
export default peopleSlice.reducer;
