import { css } from 'styled-components';

/**
 * This file is here to ensure UI consistency
 * You **MUST** sync with your designer at the start of the project to check
 * what colors/typos/grid unit you are going to use, and stick to it.
 */

// This file is where the variables are defined, so we can disable stylelint here
// stylelint-disable

//media queries variables
export const size = {
  tablet: '768px',
  laptop: '1024px',
};

/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 * and elements with fixed width/height
 */
const SPACING_UNIT = 4;
const MEASUREMENT_UNIT = 'px';
export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;

export const HEADER_HEIGHT = {
  MOBILE: getSpacing(11),
  DESKTOP: getSpacing(15),
};

/**
 * Use this palette in your components
 * If a new color is in the mockups, check with the designer
 * that the project really need a new color and add it here.
 * As to naming, the best name is the name used by the designer
 */
export const colorPalette = {
  cubikBlue: '#005d9a',
  blue: '#28b1e5',
  blueLight: '#d2ecf9',
  blueDark: '#005d9a',
  blueDarker: '#1c3d73',
  validationGreen: '#54D6A4',
  validationOrange: '#DBA72C',
  red: '#bd2657',
  redLight: '#f29ca5',
  redDark: '#8f1347',
  grey: '#dfd7d5',
  greyDark: '#413736',
  greyLight: '#f6F6F6',
  yellow: '#fad272',
  yellowDark: '#f9B234',
  yellowLight: '#ffeac1',
  white: '#FFF',
  invoicedTraining: '#9999ff',
  invoicedConsulting: '#afdfea',
  companionship: '#cfdf78',
  teamDay: '#237588',
  dayOff: '#cfd0d0',
  cellOverlayBackground: '#333C',
  // from google agenda
  gridBorderGrey: '#dadce0',
  gridHeader: '#70757a',
  feeLine: '#808080',
};

export const fontFamily = {
  main: `'Lato', 'Helvetica', 'Arial', sans-serif`,
  code: 'Monospace',
};

export const typography = {
  main: css`
    font-family: ${fontFamily.main};
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    color: ${colorPalette.greyDark};
  `,
  bold: css`
    font-weight: bold;
  `,
  light: css`
    font-weight: lighter;
  `,
  small: css`
    font-size: 12px;
  `,
  h1: css`
    font-family: ${fontFamily.main};
    font-weight: bold;
    font-size: 24px;
  `,
};

export const borderRadius = {
  medium: '4px',
  large: '12px',
};
