import { REQUEST_TYPE } from 'module/serializer';
import { Fee, RawFee } from './typings';

export const serializeFeeForFront = (data: RawFee): Fee =>
  ({
    id: data.id,
    description: data.fields.Descriptif,
    type: data.fields.Type,
    tva: data.fields.TVA,
    total: data.fields.Total,
    planningId: data.fields.Planning[0],
    distanceKm: data.fields['Distance (en km)'],
    volumeL: data.fields['Volume (en L)'],
    roundtrip: data.fields['Aller-Retour'],
    numberOfNights: data.fields['Nombre de Nuitées'],
  } as Fee);

export const serializeFeeForBack = (
  data: Fee,
  requestType: REQUEST_TYPE,
): { id?: RawFee['id']; fields: RawFee['fields'] } => {
  const fields = {
    Descriptif: data.description,
    Type: data.type,
    TVA: data.tva,
    Total: data.total,
    Planning: [data.planningId],
    'Distance (en km)': 'distanceKm' in data ? data.distanceKm : undefined,
    'Volume (en L)': 'volumeL' in data ? data.volumeL : undefined,
    'Aller-Retour': 'roundtrip' in data ? data.roundtrip : undefined,
    'Nombre de Nuitées': 'numberOfNights' in data ? data.numberOfNights : undefined,
  };

  switch (requestType) {
    case REQUEST_TYPE.POST:
      return { fields };
    case REQUEST_TYPE.PUT:
      return { id: data.id, fields };
    default:
      return { fields };
  }
};
