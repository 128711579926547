import { createSlice } from '@reduxjs/toolkit';

export type UIState = {
  /**
   * If true, any edition features will be blocked.
   */
  isEditionBlocked: boolean;
};

const initialState: UIState = {
  isEditionBlocked: true,
};

const UISlice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    toggleIsEditionBlocked: state => ({ ...state, isEditionBlocked: !state.isEditionBlocked }),
  },
});

export const { toggleIsEditionBlocked } = UISlice.actions;

export default UISlice.reducer;
