import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_USER_KEY } from './constants';

export type LoginState = Readonly<{
  loginError: string | null;
  user: string;
}>;

// @ts-ignore-next-line
const initialUserInLocalStorage: string =
  (typeof window !== 'undefined' && localStorage.getItem(LOCAL_STORAGE_USER_KEY)) ?? '';

const initialState: LoginState = {
  loginError: null,
  user: initialUserInLocalStorage,
};

const loginSlice = createSlice({
  name: 'Login',
  initialState,
  reducers: {
    userLoggedIn: (state, action: PayloadAction<{ user: string }>) => {
      localStorage.setItem(LOCAL_STORAGE_USER_KEY, action.payload.user);
      state.user = action.payload.user;
      state.loginError = null;
    },
    loginFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.loginError = action.payload.errorMessage;
    },
    userLoggedOut: () => {
      localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
      return initialState;
    },
  },
});

export const { userLoggedIn, userLoggedOut, loginFailure } = loginSlice.actions;
export type LoginAction = typeof loginSlice.actions;
export default loginSlice.reducer;
