import { getIsPeopleFromTeam, Teams } from 'module/Teams';
import { RootState } from 'redux/types';
import { isCubikMail } from './utils';

export const getLoginError = (store: RootState) => store.login.loginError;
export const getUserEmail = (store: RootState) => {
  return store.login.user;
};

export const getLoggedInUser = (store: RootState) => {
  return store.people.ids
    .map(id => store.people.entities[id])
    .find(people => people.email === store.login.user);
};

export const getUserId = (store: RootState) => {
  const email = store.login.user;
  if (email) {
    return store.people.ids.find(id => store.people.entities[id].email === email) ?? null;
  }

  return null;
};

export const isLoggedIn = (store: RootState) => {
  return isCubikMail(store.login.user);
};

export const isUserRegistered = (store: RootState) => {
  return store.people.ids.map(id => store.people.entities[id].email).includes(store.login.user);
};

const FREELANCE_TEAM_LABEL = 'Freelance';

export const getIsUserFreelance = (store: RootState) => {
  const currentUser = getLoggedInUser(store);

  if (currentUser === undefined) return false;

  const freelanceTeamId = store.teams.ids.find(
    id => store.teams.entities[id].name === FREELANCE_TEAM_LABEL,
  );
  if (freelanceTeamId === undefined) return false;

  return currentUser.teams[0] === freelanceTeamId;
};

export const getIsLoggedInUserTeamLeader = (store: RootState) => {
  const currentUser = getLoggedInUser(store);

  if (currentUser === undefined) return false;

  return getIsPeopleFromTeam(currentUser.id, Teams.LEADER)(store);
};

export const getIsLoggedInUserAdmin = (store: RootState) => {
  const currentUser = getLoggedInUser(store);

  return currentUser?.isAdmin === true;
};
