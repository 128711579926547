export const isDev = process.env.NODE_ENV === 'development';

export const deepEqual = function (x: any, y: any) {
  if (x === y) {
    return true;
  } else if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
    // eslint-disable-next-line eqeqeq
    if (Object.keys(x).length != Object.keys(y).length) return false;
    for (const prop in x) {
      // eslint-disable-next-line no-prototype-builtins
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }
    return true;
  } else return false;
};
