import request from 'superagent';
import Emittery from 'emittery';

const backendBaseUrl = process.env.REACT_APP_API_BASE_URL ?? '';
export const DATA_ENDPOINT = '/data/';

type Method = 'get' | 'post' | 'put' | 'delete';
type Events = {
  error: Error;
};

class Client extends Emittery<Events> {
  baseUrl: string;
  agent: request.SuperAgentStatic & request.Request;

  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
    this.agent = request.agent();
    this.agent.accept('application/json');
    this.agent.withCredentials();
  }

  async request(method: Method, endpoint: string, data: object | null = null) {
    const url = /^https?:\/\//.test(endpoint) ? endpoint : `${this.baseUrl}${endpoint}`;
    let promise = this.agent[method](url);

    if (['post', 'put', 'delete'].includes(method) && data) {
      promise = promise.send(data);
    }
    try {
      const { body, text } = await promise;
      return body ?? text;
    } catch (e) {
      this.emit('error', e as Error);
      throw e;
    }
  }

  get(endpoint: string) {
    return this.request('get', endpoint);
  }

  post(endpoint: string, data: object) {
    return this.request('post', endpoint, data);
  }

  put(endpoint: string, data: object) {
    return this.request('put', endpoint, data);
  }

  delete(endpoint: string, data: object) {
    return this.request('delete', endpoint, data);
  }
}

const client = new Client(backendBaseUrl);

export default client;
