import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPeopleFilterType, getValidCustomSavedSelections } from './selectors';
import { updateCustomPeopleSavedSelections } from './slice';
import { extractCustomPeopleSavedSelectionIndex } from './utils';

export const useCurrentCustomPeopleSavedSelection = () => {
  const peopleFilterType = useSelector(getPeopleFilterType);
  const customPeopleSavedSelections = useSelector(getValidCustomSavedSelections);
  const currentCustomPeopleSavedSelectionIndex =
    extractCustomPeopleSavedSelectionIndex(peopleFilterType);
  const currentCustomPeopleSavedSelection =
    currentCustomPeopleSavedSelectionIndex !== undefined
      ? customPeopleSavedSelections[Number(currentCustomPeopleSavedSelectionIndex)]
      : undefined;

  return { currentCustomPeopleSavedSelectionIndex, currentCustomPeopleSavedSelection };
};

export const useAddCustomPeopleSavedSelection = () => {
  const customPeopleSavedSelections = useSelector(getValidCustomSavedSelections);
  const dispatch = useDispatch();

  const addCustomPeopleSavedSelection = useCallback(
    (selection: { name: string; people: string[] }) => {
      const newIndex = customPeopleSavedSelections.length;

      dispatch(
        updateCustomPeopleSavedSelections([...customPeopleSavedSelections, { ...selection }]),
      );

      return newIndex;
    },
    [customPeopleSavedSelections, dispatch],
  );

  return { addCustomPeopleSavedSelection };
};

export const useUpdateCustomPeopleSavedSelection = () => {
  const customPeopleSavedSelections = useSelector(getValidCustomSavedSelections);
  const dispatch = useDispatch();

  const updateCustomPeopleSavedSelection = useCallback(
    (indexToUpdate: number, selection: { name: string; people: string[] }) => {
      const newCustomPeopleSavedSelection = [...customPeopleSavedSelections];
      newCustomPeopleSavedSelection[Number(indexToUpdate)] = { ...selection };
      dispatch(updateCustomPeopleSavedSelections(newCustomPeopleSavedSelection));
    },
    [customPeopleSavedSelections, dispatch],
  );

  return { updateCustomPeopleSavedSelection };
};

export const useDeleteCustomPeopleSavedSelection = () => {
  const customPeopleSavedSelections = useSelector(getValidCustomSavedSelections);
  const dispatch = useDispatch();

  const deleteCustomPeopleSavedSelection = useCallback(
    (indexToRemove: number) => {
      const newCustomPeopleSavedSelection = [...customPeopleSavedSelections];

      newCustomPeopleSavedSelection.splice(indexToRemove, 1);

      dispatch(updateCustomPeopleSavedSelections(newCustomPeopleSavedSelection));
    },
    [customPeopleSavedSelections],
  );

  return { deleteCustomPeopleSavedSelection };
};
