import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Planning } from 'module/Planning';
import { People } from 'module/People';
import { createNewPlanning } from 'module/Planning/utils';

export type ModalData = {
  planning: Planning;
  peopleData: People;
  date: Date;
};

export type ModalState = {
  isOpen: boolean;
  planning: Planning;
  hasChanged: boolean;
};

export type ModalAction = PayloadAction<ModalData | undefined>;

const initialState: ModalState = {
  isOpen: false,
  planning: createNewPlanning('', new Date()),
  hasChanged: false,
};

const ModalSlice = createSlice({
  name: 'Modal',
  initialState,
  reducers: {
    toggleModal: (state, action: PayloadAction<boolean | undefined>) => {
      state.isOpen = action.payload ?? !state.isOpen;
    },
    updateModalPlanning: (state, action: PayloadAction<ModalData>) => {
      const { planning, peopleData, date } = action.payload;
      if (planning) {
        state.planning = planning;
      } else if (peopleData && date) {
        state.planning = createNewPlanning(peopleData.id, date);
      } else {
        state.planning = initialState.planning;
      }
    },
    setModalChanged: (state, action: PayloadAction<boolean>) => {
      state.hasChanged = action.payload;
    },
  },
});

export const { toggleModal, updateModalPlanning, setModalChanged } = ModalSlice.actions;

export default ModalSlice.reducer;
