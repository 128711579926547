import { useDispatch } from 'react-redux';
import { ModalAction, ModalData, toggleModal, updateModalPlanning } from './slice';

export const useToggleModal = (): ((isModalOpen?: boolean) => void) => {
  const dispatch = useDispatch();
  return isModalOpen => dispatch(toggleModal(isModalOpen));
};

export const useUpdateModalPlanning = (): ((modalData: ModalData) => ModalAction) => {
  const dispatch = useDispatch();
  return (modalData: ModalData) => dispatch(updateModalPlanning(modalData));
};

export default { useToggleModal, useUpdateModalPlanning };
